import {
  Alert,
  Body,
  Footer,
  Header,
  DevelopmentInfo,
  Navbar,
  navbarPermissionsHelper,
  useAlerts,
  useUsers,
} from "common";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { navbar } from "app/shared/config";
import { MainContainer } from "common/components/Main-Container/MainContainer";
import { LicenseInfo as MuiLicenseInfo } from "@mui/x-license";
import ReactGA from "react-ga4";

export const App = () => {
  const { alert } = useAlerts();
  const { t } = useTranslation();
  const { loggedInUser, currentUser, accessDenied } = useUsers();

  MuiLicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

  if (currentUser.isLoaded && !accessDenied) {
    ReactGA.initialize(window.GA_MEASUREMENT_ID);
  }

  const permittedNavbar = useMemo(() => {
    // If /users/me hasn't returned yet,
    // just send back the navbar config as-is.
    if (!currentUser.isLoaded) {
      return navbar.items;
    }
    return navbarPermissionsHelper(navbar.items, currentUser.permissions);
  }, [currentUser]);

  return (
    <>
      <DevelopmentInfo />
      <Header
        logoTitle={t("app.logoTitle")}
        logoSubtitle={t("app.logoSubtitle")}
        userDisplayName={loggedInUser.displayName}
      />
      <Body>
        {currentUser.isLoaded ? (
          <>
            <Navbar menuItems={permittedNavbar} />
            {alert.exists && <Alert />}
            <MainContainer idAttr="project-slug-main" />
          </>
        ) : (
          <>{alert.exists && <Alert />}</>
        )}
      </Body>
      <Footer
        copyrightYear={t("app.copyrightYear")}
        productVersion={window.PRODUCT_VERSION || process.env.REACT_APP_VERSION}
      />
    </>
  );
};
