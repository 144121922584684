//
// Helper methods for managing the Navbar & Config files.
//

/**
 * Helper function to convert a navbar config list to one that only
 * contains those navbar items the current user has permissions
 * to view.
 *
 * @param {object[]} menuItems A list of navbar items
 * @param {object} permissions A Hateoas _links object
 * @returns A navbar config with only permitted locations
 *
 * @see /src/app/shared/config.js For menuItems shape & options
 */
export const navbarPermissionsHelper = (menuItems, permissions) => {
  const permittedNavbar = menuItems
    .map((item) => {
      // Parent item? Has children?
      if (item.menuItem?.length > 0) {
        // New parent with permitted items only
        let menu = {
          name: item.name,
          menuItem: [],
        };

        // Check each child
        for (const subItem of item.menuItem) {
          if (permissions?.[subItem.permName]) {
            // Permission exists, add to new parent
            menu.menuItem.push(subItem);
          }
        }

        // No permitted children
        if (menu.menuItem.length === 0) return null;

        // Return permitted menu & children items
        return menu;
      } else {
        // No children items, check for navigable route
        if (item.route) {
          return permissions[item.permName] ? item : null;
        }

        // No route to check, just return
        return item;
      }
    })
    // Remove empty entries
    .filter((permitted) => permitted);

  return permittedNavbar;
};
