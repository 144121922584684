import { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { format, addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  InputAdornment as MuiInputAdornment,
} from "@mui/material";
import {
  ArrowBack as MuiArrowBackIcon,
  NavigateNext as MuiNavigateNextIcon,
} from "@mui/icons-material";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers";
import { useAlerts, useUsers } from "common";
import {
  putComponent,
  postComponent,
  deleteComponent,
  getCommitmentNameById,
  getComponentByIdBrief,
  getComponentNamesLowercase,
} from "app/services/componentsService";
import { getCurrentFiscalYear } from "app/services/commitmentsService";
import { FormButton } from "app/shared/ui/FormButton";
import { FormattedCurrencyField } from "app/shared/ui/CurrencyFormat";
import {
  getLastYearProjectionHeading,
  getCurrentYearBudgetHeading,
  getNextYearPlanHeading,
  getYearAfterNextPlanHeading,
} from "app/components/Budgeting/Budgeting";
import { getIsValidDate } from "app/shared/utils";
import { disabledTextFieldStyles } from "app/shared/ui/sharedStyles";

export const CommitmentComponentRequestForm = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { clearAlert, setAlert } = useAlerts();
  let { currentFiscalYear } = location.state || {};
  currentFiscalYear = currentFiscalYear || getCurrentFiscalYear();

  const isViewOnly = props.pageType === "view" || props.pageType === "delete";
  const isDeleteRecord = props.pageType === "delete";
  const isEditRecord = props.pageType === "edit";
  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const otherParent = props.parent === "other";

  const handleClose = () => {
    navigate("/budgeting");
  };

  const handleDelete = () => {
    setLoading(true);
    deleteComponent(
      params.commitmentId,
      params.componentId,
      setLoading,
      clearAlert,
      setAlert,
      navigate,
      t
    );
  };

  const _handleSubmit = (values, setFieldError, setSubmitting) => {
    values.budgetPlan = values.budgetPlan || {};
    values.budgetPlan.budgetFiscalYear = currentFiscalYear;

    const args = [
      values,
      piOrComponentOwnerValue,
      setLoading,
      params.commitmentId,
      params.componentId,
      commitmentName,
      setFieldError,
      setSubmitting,
      clearAlert,
      setAlert,
      navigate,
      t,
    ];
    params.componentId ? putComponent(...args) : postComponent(...args);
  };

  const shrinkProps = isViewOnly && { shrink: true };

  const componentFormField =
    "Commitments.commitmentComponents.create.form.fields";

  const getBreadcrumbTxt = () => {
    if (props.pageType === "new") {
      return t(
        `Commitments.commitmentComponents.createRequest.breadcrumbTitle`
      );
    } else if (isDeleteRecord) {
      return t(
        `Commitments.commitmentComponents.deleteRequest.breadcrumbTitle`
      );
    } else if (isViewOnly) {
      return t(`Commitments.commitmentComponents.viewRequest.breadcrumbTitle`);
    } else {
      return t(`Commitments.commitmentComponents.editRequest.breadcrumbTitle`);
    }
  };

  const getMainHeadingTxt = () => {
    if (props.pageType === "new") {
      return t(`Commitments.commitmentComponents.createRequest.title`, {
        commitmentName: commitmentName,
      });
    } else if (isDeleteRecord) {
      return t(`Commitments.commitmentComponents.deleteRequest.title`, {
        commitmentName: commitmentName,
      });
    } else if (isViewOnly) {
      return t(`Commitments.commitmentComponents.viewRequest.title`, {
        commitmentName: commitmentName,
      });
    } else {
      return t(`Commitments.commitmentComponents.editRequest.title`, {
        commitmentName: commitmentName,
      });
    }
  };

  const inputVariant = isViewOnly ? "standard" : "outlined";
  const isRequired = !isViewOnly;

  // State variable and its function to maintain commitment name to show in title
  const [commitmentName, setCommitmentName] = useState("");
  const [, setLoading] = useState(false);
  const [piOrComponentOwnerValue] = useState("");
  const [componentNameList, setComponentNameList] = useState([]);
  // State variable and its function to maintain closed notes and closed details view
  const [, setClosed] = useState(false);
  // State variable and its function to maintain error state for invalid
  const [isValidDate, setIsValidDate] = useState(true);

  const getInputVariant = (readOnly) => {
    return readOnly ? "standard" : inputVariant || "outlined";
  };

  const [formDataResponse, setFormDataResponse] = useState({
    componentName: "",
    componentDescription: "",
    componentDate: format(new Date(currentFiscalYear - 1, 8, 1), "MM/dd/yyyy"),
    componentAmount: "0.00",
    project: "",
    componentType: "REQUEST",
    startDate: null,
    expirationDate: null,
    componentNotes: "",
    budgetPlan: {
      budgetFiscalYear: currentFiscalYear,
      comments: "",
      fyProjectionOne: 0,
      fyBudget: 0,
      fyPlanOne: 0,
      fyPlanTwo: 0,
    },
  });

  const commitmentValidation = Yup.object().shape({
    componentName: Yup.string()
      .required(
        t("globals.form.fieldIsRequired", {
          fieldName: t(`${componentFormField}.componentName.label`),
        })
      )
      .test(
        "existsCheck",
        t(`${componentFormField}.componentName.validation.isDuplicate`),
        (value) => {
          if (
            value &&
            (!isEditRecord ||
              (isEditRecord && formDataResponse.componentName !== value))
          ) {
            return !componentNameList?.includes(value.toLowerCase());
          } else return true;
        }
      ),
    componentAmount: Yup.string().required(),
    componentDate: Yup.string().required(),
    closedDate: Yup.mixed().when("closed", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
    closedDetails: Yup.string().when("closed", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getCommitmentNameById(
      setCommitmentName,
      params.commitmentId,
      setAlert,
      clearAlert,
      cancelSource
    );
    getComponentNamesLowercase(
      params.commitmentId,
      setComponentNameList,
      setLoading,
      setAlert,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
  }, [params.commitmentId, setAlert, clearAlert]);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    const beforeSend = () => {
      clearAlert();
    };
    const onSuccess = (data) => {
      setFormDataResponse({
        componentName: data.componentName,
        componentDescription: data.componentDescription,
        componentDate: data.componentDate,
        componentAmount: data.componentAmount,
        totalComponentAmountRequested: data.totalComponentAmountRequested,
        totalComponentAmountApproved: data.totalComponentAmountApproved,
        componentType: data.componentType,
        startDate: data.startDate ?? null,
        expirationDate: data.expirationDate ?? null,
        durationNotes: data.durationNotes,
        componentNotes: data.componentNotes,
        closed: data.closed,
        recordNumber: data.recordNumber,
        budgetPlan: data.budgetPlan,
        templateSourceType: data.templateSourceType,
      });
      setClosed(data.closed);
    };
    const onError = (error) => {
      if (!axios.isCancel(error)) {
        setAlert("error", error.message);
      }
    };

    if (params.componentId) {
      getComponentByIdBrief(
        params.commitmentId,
        params.componentId,
        cancelSource,
        beforeSend,
        onSuccess,
        onError
      );
    }
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [params.commitmentId, params.componentId]);

  return (
    ((props.pageType === "new" && permissions.CREATE_REQUEST_COMPONENTS) ||
      (props.pageType === "edit" && permissions.EDIT_REQUEST_COMPONENTS) ||
      (props.pageType === "delete" && permissions.DELETE_REQUEST_COMPONENTS) ||
      (props.pageType === "view" && permissions.VIEW_REQUEST_COMPONENTS)) && (
      <MuiGrid container>
        {props.pageType !== "newTab" && (
          <MuiGrid container item xs={12}>
            <MuiGrid item container xs={6} justifyContent="flex-start">
              <MuiBreadcrumbs
                separator={<MuiNavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink
                  style={{ textDecoration: "none" }}
                  to={`/budgeting`}
                >
                  <MuiTypography color="textSecondary" variant="body2">
                    {t("Budgeting.mainView.title")}
                  </MuiTypography>
                </RouterLink>
                <MuiTypography color="textPrimary" variant="subtitle1">
                  {getBreadcrumbTxt()}
                </MuiTypography>
              </MuiBreadcrumbs>
            </MuiGrid>
            {isViewOnly && !otherParent && (
              <MuiGrid
                item
                container
                xs={4}
                justifyContent="flex-end"
                spacing={2}
              >
                <RouterLink
                  style={{ textDecoration: "none" }}
                  to={`/budgeting`}
                >
                  <MuiButton startIcon={<MuiArrowBackIcon />}>
                    {t("Commitments.view.goBackButtonLabel")}
                  </MuiButton>
                </RouterLink>
              </MuiGrid>
            )}
          </MuiGrid>
        )}
        <MuiGrid item xs={12} sx={{ padding: "8px 0" }}>
          <MuiTypography variant="h1">{getMainHeadingTxt()}</MuiTypography>
        </MuiGrid>
        {/* Formik - Wrapper of library `Formik` which set/reset/submit form values
        to create/edit a commitment Component*/}
        <Formik
          // initialValues - User to store the Formik form's initial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(false);
            _handleSubmit(values, setFieldError, setSubmitting);
          }}
          validationSchema={commitmentValidation}
          validateOnMount={false}
        >
          {(formikProps) => {
            const /** !Object */ {
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                dirty,
                isValid,
              } = formikProps;
            return (
              // Native form element to submit the form values
              <MuiGrid item xs={10}>
                <form onSubmit={handleSubmit}>
                  {/* FormControlContainer - Flex container to wrap all the form flex items */}
                  <MuiGrid item container xs={12} direction="column" gap="16px">
                    <MuiGrid
                      item
                      xs={10}
                      container
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      <MuiGrid item xs={12}>
                        <MuiTypography align="right">&nbsp;</MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Component Name - Multiline Textfield Input */}
                      <MuiGrid item xs={12}>
                        <MuiTextField
                          id="componentName"
                          label={t(`${componentFormField}.componentName.label`)}
                          required={isRequired}
                          value={values.componentName}
                          onChange={handleChange}
                          multiline
                          variant={getInputVariant(
                            formDataResponse?.templateSourceType
                          )}
                          autoComplete="off"
                          fullWidth
                          inputProps={{
                            maxLength: 250,
                            readOnly: formDataResponse?.templateSourceType,
                          }}
                          helperText={
                            errors.componentName ? errors.componentName : ""
                          }
                          error={Boolean(errors.componentName)}
                          disabled={isViewOnly}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        {/* Total Component Amount - Textfield Input */}
                        <NumericFormat
                          id="componentAmount"
                          label={t(
                            `${componentFormField}.totalComponentAmountRequested.label`
                          )}
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          customInput={MuiTextField}
                          isAllowed={(args) => {
                            const { value } = args;
                            return value <= 999999999.99;
                          }}
                          allowNegative={false}
                          InputProps={{
                            startAdornment: (
                              <MuiInputAdornment position="start">
                                $
                              </MuiInputAdornment>
                            ),
                          }}
                          value={values.componentAmount}
                          onChange={(event, value) => {
                            setFieldValue(
                              "componentAmount",
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          variant={inputVariant}
                          autoComplete="off"
                          fullWidth
                          sx={disabledTextFieldStyles}
                          disabled={isViewOnly}
                          required={isRequired}
                          InputLabelProps={{ ...shrinkProps }}
                        />
                      </MuiGrid>
                    </MuiGrid>

                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        {/* Component Date - MuiDatePicker */}
                        {/* Closed Date - Date Picker Input */}
                        <MuiLocalizationProvider
                          dateAdapter={MuiAdapterDateFns}
                        >
                          <MuiDatePicker
                            id="startDate"
                            format="MM/dd/yyyy"
                            label={t(`${componentFormField}.startDate.label`)}
                            value={getIsValidDate(values.startDate)}
                            onChange={(value) => {
                              setFieldValue("startDate", value);
                            }}
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                            }}
                            inputVariant={inputVariant}
                            inputProps={{ autoComplete: "off" }}
                            maxDate={
                              (values.expirationDate &&
                                new Date(values.expirationDate)) ||
                              undefined
                            }
                            maxDateMessage={t(
                              `${componentFormField}.startDate.validation.maxDateMessage`
                            )}
                            clearable
                            disabled={isViewOnly}
                            keyboardIcon={isViewOnly ? false : undefined}
                            InputLabelProps={{ ...shrinkProps }}
                            fullWidth
                            sx={disabledTextFieldStyles}
                            renderInput={(args) => <MuiTextField {...args} />}
                          />
                        </MuiLocalizationProvider>
                      </MuiGrid>
                      <MuiGrid item xs={6}>
                        {/* Component Date - MuiDatePicker */}
                        {/* Closed Date - Date Picker Input */}
                        <MuiLocalizationProvider
                          dateAdapter={MuiAdapterDateFns}
                        >
                          <MuiDatePicker
                            id="expirationDate"
                            format="MM/dd/yyyy"
                            label={t(
                              `${componentFormField}.expirationDate.label`
                            )}
                            value={getIsValidDate(values.expirationDate)}
                            onChange={(value) => {
                              setFieldValue("expirationDate", value);
                            }}
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                            }}
                            inputVariant={inputVariant}
                            inputProps={{ autoComplete: "off" }}
                            minDate={
                              (values.startDate &&
                                addDays(new Date(values.startDate), 1)) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${componentFormField}.expirationDate.validation.minDateMessage`
                            )}
                            clearable
                            disabled={isViewOnly}
                            keyboardIcon={isViewOnly ? false : undefined}
                            InputLabelProps={{ ...shrinkProps }}
                            fullWidth
                            sx={disabledTextFieldStyles}
                            renderInput={(args) => <MuiTextField {...args} />}
                          />
                        </MuiLocalizationProvider>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={12}>
                        {/* Duration Notes - Textfield Input */}
                        <MuiTextField
                          id="componentNotes"
                          label={t(
                            `${componentFormField}.componentNotes.label`
                          )}
                          value={values.componentNotes}
                          onChange={handleChange}
                          multiline
                          variant={inputVariant}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          disabled={isViewOnly}
                          InputLabelProps={{ ...shrinkProps }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                    </MuiGrid>

                    {/* Budget Plan fields */}
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={3}>
                        <FormattedCurrencyField
                          id={`budgetPlan.fyProjectionOne`}
                          required={false}
                          label={getLastYearProjectionHeading(
                            t,
                            currentFiscalYear
                          )}
                          value={values.budgetPlan?.fyProjectionOne}
                          onChange={(event, value) => {
                            setFieldValue(
                              `budgetPlan.fyProjectionOne`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={inputVariant}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={3}>
                        <FormattedCurrencyField
                          id={`budgetPlan.fyBudget`}
                          required={false}
                          label={getCurrentYearBudgetHeading(
                            t,
                            currentFiscalYear
                          )}
                          value={values.budgetPlan?.fyBudget}
                          onChange={(event, value) => {
                            setFieldValue(
                              `budgetPlan.fyBudget`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={inputVariant}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={3}>
                        <FormattedCurrencyField
                          id={`budgetPlan.fyPlanOne`}
                          required={false}
                          label={getNextYearPlanHeading(t, currentFiscalYear)}
                          value={values.budgetPlan?.fyPlanOne}
                          onChange={(event, value) => {
                            setFieldValue(
                              `budgetPlan.fyPlanOne`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={inputVariant}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={3}>
                        <FormattedCurrencyField
                          id={`budgetPlan.fyPlanTwo`}
                          required={false}
                          label={getYearAfterNextPlanHeading(
                            t,
                            currentFiscalYear
                          )}
                          value={values.budgetPlan?.fyPlanTwo}
                          onChange={(event, value) => {
                            setFieldValue(
                              `budgetPlan.fyPlanTwo`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={inputVariant}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                        />
                      </MuiGrid>
                    </MuiGrid>

                    {isDeleteRecord ? (
                      <MuiGrid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <MuiGrid
                          container
                          item
                          justifyContent="flex-end"
                          xs={3}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            delete={handleDelete}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    ) : !isViewOnly ? (
                      <MuiGrid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <MuiGrid
                          container
                          item
                          justifyContent="flex-end"
                          xs={3}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            save={{
                              disabled: !dirty || !isValid || !isValidDate,
                            }}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    ) : (
                      !otherParent && (
                        <MuiGrid
                          item
                          xs={10}
                          container
                          justifyContent="flex-end"
                          spacing={2}
                        >
                          {/* Back Button */}
                          <MuiGrid container item justifyContent="flex-end">
                            <RouterLink
                              style={{ textDecoration: "none" }}
                              to={`/commitments`}
                            >
                              <MuiButton
                                variant="outlined"
                                size="large"
                                startIcon={<MuiArrowBackIcon />}
                              >
                                {t("Commitments.view.goBackButtonLabel")}
                              </MuiButton>
                            </RouterLink>
                          </MuiGrid>
                        </MuiGrid>
                      )
                    )}
                  </MuiGrid>
                </form>
              </MuiGrid>
            );
          }}
        </Formik>
      </MuiGrid>
    )
  );
};
