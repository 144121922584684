export const navbar = {
  items: [
    {
      name: "Commitments",
      menuItem: [],
      route: "/commitments",
      permName: "VIEW_COMMITMENTS",
    },
    {
      name: "Expenses",
      menuItem: [],
      route: "/expenses",
      permName: "VIEW_EXPENSES",
    },
    {
      name: "Transfers",
      menuItem: [],
      route: "/transfers",
      permName: "VIEW_TRANSFER",
    },
    {
      name: "Budgeting",
      menuItem: [],
      route: "/budgeting",
      permName: "VIEW_BUDGET",
    },
    {
      name: "Requests",
      menuItem: [],
      route: "/requests",
      permName: "VIEW_REQUESTS",
    },
    {
      name: "PTAs",
      menuItem: [],
      route: "/ptas",
      permName: "VIEW_PTAS",
    },
  ],
};
